import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Link, Typography, Box } from '@mui/material'
// components
import Page from '../components/Page'
import AuthSidebar from '../layouts/AuthSidebar'
import MapGoogle from '../components/googleMap/GoogleMap'
import Signature from '../components/signature'
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(() => ({
  width: '100vw',
  margin: 'auto',
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  padding: '20px'
}))

// ----------------------------------------------------------------------

export default function Mapa() {
  return (
    <RootStyle title="Mapa">
      <AuthSidebar>
        <Typography variant="h4" sx={{ px: 10 }} align="center">
          Bem vindo,
        </Typography>
        <Typography variant="h1" sx={{ px: 10 }} align="center">
          Portal Egressos
        </Typography>
      </AuthSidebar>
      <ContentStyle>
        <Box sx={{ marginBottom: '10px', marginLeft: { sm: '7rem', md: 0 }, marginTop: { xs: '3rem', md: 0, sm: 0 } }}>
          <Typography variant="h4">Nossos Egressos</Typography>
          <Typography variant="subtitle2">
            Digite o nome de uma cidade ou país e localize nossos Egressos pelo globo. Você também é nosso Egresso?
            Então,&nbsp;
            <Link to="/register" component={RouterLink}>
              clique aqui
            </Link>
            &nbsp;para realizar seu registro ou{' '}
            <Link to="/login" component={RouterLink}>
              clique aqui
            </Link>{' '}
            aqui para entrar.
          </Typography>
        </Box>
        <MapGoogle />
        {/* <Signature /> */}
      </ContentStyle>
    </RootStyle>
  )
}
