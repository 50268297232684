import PropTypes from 'prop-types'
import { Card, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { MHidden } from '../components/@material-extend'
import AuthLayout from './AuthLayout'
import LogoUniversity from '../components/LogoUniversity'
import { useSettings } from '../Context/SettingsContext'

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 364,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}))

const FooterStyle = styled('footer')(({ theme }) => ({
  bottom: 40,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}))

AuthSidebar.propTypes = {
  children: PropTypes.node
}

export default function AuthSidebar({ children }) {
  const { settingsInfo } = useSettings()

  return (
    <MHidden width="mdDown">
      <SectionStyle>
        <AuthLayout />
        {children}
        <FooterStyle>
          <LogoUniversity universityImage={settingsInfo?.university_image} />
        </FooterStyle>
      </SectionStyle>
    </MHidden>
  )
}
