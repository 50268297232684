import { Routes, Route, Navigate } from 'react-router-dom'
// routes
/* import router from './routes' */
// theme
import ThemeConfig from './theme'
import GlobalStyles from './theme/globalStyles'
// components
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart'
import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
import Login from './pages/Login'
import Register from './pages/Register'
import Registrado from './pages/Registrado'
import DashboardApp from './pages/DashboardApp'
import Egressos from './pages/Egressos'
import NotFound from './pages/Page404'
import EditEgresso from './pages/EditEgresso'
import RequireAuth from './components/RequireAuth'
import Forgot from './pages/Forgot'
import ResetPassword from './pages/ResetPassword'
import Mapa from './pages/Mapa'
import Users from './pages/Users'
import UserForm from './components/_dashboard/usuarios/UserForm'
import Profile from './pages/Profile'
import Configuracoes from './pages/Configuracoes'
import ImportarEgresso from './pages/ImportarEgresso'
import AreasAtuacao from './pages/AreasAtuacao'
import AreasAtuacaoForm from './components/_dashboard/areasAtuacao/AreaAtuacaoForm'
// ----------------------------------------------------------------------
export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Routes>
        <Route element={<RequireAuth allowedRoles={['admin']} />}>
          <Route path="/app" element={<DashboardLayout />}>
            <Route path="dashboard" element={<DashboardApp />} />
            <Route path="" element={<DashboardApp />} />
            <Route path="egressos" element={<Egressos />} />
            <Route path="egressos/editar" element={<EditEgresso />} />
            <Route path="egressos/importar" element={<ImportarEgresso />} />
            <Route path="usuarios" element={<Users />} />
            <Route path="usuarios/editar" element={<UserForm />} />
            <Route path="usuarios/criar" element={<UserForm />} />
            <Route path="areas-atuacao" element={<AreasAtuacao />} />
            <Route path="areas-atuacao/editar" element={<AreasAtuacaoForm />} />
            <Route path="areas-atuacao/criar" element={<AreasAtuacaoForm />} />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={['egresso', 'admin']} />}>
          <Route path="/app" element={<DashboardLayout />}>
            <Route path="profile" element={<Profile />} />
            <Route path="configuracoes" element={<Configuracoes />} />
          </Route>
        </Route>

        <Route path="/" element={<LogoOnlyLayout />}>
          <Route path="/" element={<Navigate to="/mapa" />} />
          <Route path="mapa" element={<Mapa />} />
          <Route path="login" element={<Login />} />
          <Route path="/esqueci-senha" element={<Forgot />} />
          <Route path="/nova-senha-enviada" element={<ResetPassword />} />
          <Route path="register" element={<Register />} />
          <Route path="registro-concluido" element={<Registrado />} />
          <Route path="404" element={<NotFound />} />
          <Route path="/app" element={<Navigate to="/app/dashboard" />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
      </Routes>
    </ThemeConfig>
  )
}
