import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
// material
import { Box } from '@mui/material'
// ----------------------------------------------------------------------
import { useMe } from '../Context/MeContext'
import { useSettings } from '../Context/SettingsContext'

Logo.propTypes = {
  sx: PropTypes.object
}

export default function Logo({ sx }) {
  const { role } = useMe()
  const { settingsInfo } = useSettings()

  const navigate = useNavigate()

  const checkAuth = () => {
    if (role === 'admin') {
      return navigate('/app/dashboard')
    }
    if (role === 'egresso') {
      return navigate('/app/profile')
    }
    return navigate('/login')
  }

  return (
    <Box
      style={{ cursor: 'pointer' }}
      component="img"
      src={settingsInfo?.department_image}
      sx={{ width: 200, ...sx }}
      onClick={checkAuth}
    />
  )
}
