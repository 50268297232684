import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

LogoUniversity.propTypes = {
  sx: PropTypes.object
}

export default function LogoUniversity({ universityImage, sx }) {
  return <Box component="img" src={universityImage} sx={{ width: 170, ...sx }} />
}
